import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  progress: number;
  width: number;
  height: number;
  color: string;
}

const Container = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-color: lightgray;
  border-radius: ${({ height }) => `${height / 2}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
`;

const ProgressBarFill = styled.div<{ progress: number; color: string; height: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: ${({ height }) => `${height}px`};
  background-color: ${({ color }) => color};
  border-radius: ${({ height }) => `${height / 2}px`};
  transition: width 0.3s ease-in-out;
  position: absolute;
  left: 0;
`;

const ProgressText = styled.span`
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, width, height, color }) => {
  const progressPercentage = `${progress}%`;

  return (
    <Container width={width} height={height}>
      <ProgressBarFill progress={progress} color={color} height={height}>
        <ProgressText>{progressPercentage}</ProgressText>
      </ProgressBarFill>
    </Container>
  );
};


