import { Dispatch, SetStateAction } from 'react';
import { backendUrl } from '../i18n';
import axios from "axios";

export const createNewConversation = async (setConversationId: Dispatch<SetStateAction<string | null>>) => {
  const response = await axios.get<{ conversationId: string, conversationIdExpiryTime: string }>(
    `${backendUrl}/getNewConversationToken`
  );
  localStorage.setItem('conversationId', response.data.conversationId);
  localStorage.setItem('conversationIdExpiryTime', response.data.conversationIdExpiryTime);  
  
  setConversationId(response.data.conversationId);
}