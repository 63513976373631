
import React from 'react';
import {
  Flex,
} from 'rebass';
import { conversationIdAndTopicNameType, PromptModes } from './styled/various';
import { 
  colors,
} from './styled/various';
import { useTranslation } from 'react-i18next';
import { Categories } from '../components/Categories';
import { MainButton } from './MainButton';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';


type LeftMenuDesktopType = {
  conversationIdsAndNames: conversationIdAndTopicNameType[] | undefined,
  createNewConversation: any,  
  currentConversationId: string | null,
  currentCategory: string,  
  setConversationId: any,
  changeConversationId: (newConversationId: string) => void,
  handleCategoryChange: (newCategory: string) => void,  
  setPromptMode: (newPrompt: PromptModes) => void,    
}

export const LeftMenuDesktop = ({ 
  conversationIdsAndNames,
  createNewConversation,  
  currentConversationId,
  currentCategory,  
  setConversationId,
  changeConversationId,  
  handleCategoryChange,
  setPromptMode
} 
: LeftMenuDesktopType) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={'column'} mr='20px'>

          <MainButton 
            key='analyzeYourDocuments'
            title={t('analyzeYourDocuments')}
            backgroundColor={colors.dark}
            onClick={() => setPromptMode('pdfMode')}
            faSymbol={faUpload}
          />     

          <Categories
            key='categoriesDesktop' 
            handleCategoryChange={handleCategoryChange} 
            currentCategory={currentCategory}
          />

          <br/>

        <MainButton 
          key='createANewConversation'
          title={t('createANewConversation')}
          backgroundColor={colors.dark}
          onClick={() => {
            createNewConversation(setConversationId);
            setPromptMode('regularMode');
          }}
          faSymbol={faPlus}          
        />   
        {/* Main items in the left menu */}
        {conversationIdsAndNames && conversationIdsAndNames.map((row, index) => {
            if(!row.topicName) {
              row.topicName = '---';
            }
            return (  
              <MainButton 
                currentConversationId={currentConversationId || ''}
                changeConversationId={changeConversationId}
                setPromptMode={setPromptMode}
                key={index.toString()}
                title={row.topicName}
                conversationId={row.conversationId}
                fontSize='14px'
                onClick={() => {
                  setPromptMode('regularMode');
                  changeConversationId(row.conversationId);
                }}                            
              />
            )
          })}
        
        <br />        
    
    </Flex> 
  )
}