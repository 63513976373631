import {
  Flex,
} from 'rebass';
import { Conversation } from './Conversation';
import { MainPromptProps } from './styled/various';

export const RegularPrompt = ({
  isMobile,
  isLoadingMutation,
  currentConversation,
  conversationIsLoading,
  errorMessage,
  textAreaValue,
  captchaToken,
  handleChange,
  handleSubmit
}: MainPromptProps) => {
  return (
      <Flex flexDirection={'column'} width={isMobile ? 1 : 3 / 4}>
        <Conversation 
          isMobile={isMobile}
          isLoadingMutation={isLoadingMutation}
          currentConversation={currentConversation}
          errorMessage={errorMessage}
          textAreaValue={textAreaValue}
          captchaToken={captchaToken}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          conversationIsLoading={conversationIsLoading}            
        />                 
      </Flex> 
  )
} 
