// Dropdown.js
import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { colors } from "./styled/various";
import { PromptModes } from "./styled/various";

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    cursor: pointer;
  }  
`;

const DropdownButton = styled.button`
  background-color: ${(props) => props.color || "#3498db"};
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownItem = styled.a`
  max-width: 400px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #ddd;
  }
`;

type DropdownOptionType = {
  label: string,
  description: string
}

type DropdownProps = {
  title: string,
  options: DropdownOptionType[],
  currentOption: string,
  isMobile?: boolean,
  handleOptionChange: (newOption: string) => void,
  setPromptMode?: (newPrompt: PromptModes) => void
}

export const DropdownCustom = ({
  title, 
  options,
  currentOption,
  handleOptionChange,
  setPromptMode,
  isMobile
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleItemClick = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);  

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownWrapper 
      ref={wrapperRef}
      style={{
        marginRight: isMobile ? '10px' : '0px',
        marginBottom: isMobile ? '10px' : '0px',               
      }}
    >

      <DropdownButton 
        onClick={toggleDropdown} 
        color={colors.dark}
      >
        {title}
        <FontAwesomeIcon icon={faChevronDown} />
      </DropdownButton>
      
      <DropdownContent isOpen={isOpen}>
        {options && options.map((option, index) => { 
          return (
            
            <DropdownItem 
              onClick={() => {
                setPromptMode && setPromptMode('regularMode');
                handleOptionChange(option.label);
                handleItemClick();
              }}
            >

              {option.description}
              
              {option.label === currentOption && 
                  <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '10px' }} />
              }

            </DropdownItem>
          )
        })}
      </DropdownContent>

    </DropdownWrapper>
  );
};

