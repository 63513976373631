import React from 'react';
import axios from 'axios';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { 
  FormContainer, 
  Logo
} from './styled/various';
// import { isEmpty } from "lodash";
import {
  Flex,
} from 'rebass'
import useGetConversationIds from '../hooks/useGetConversationIds';
import useGetConversationSentences from '../hooks/useGetConversationSentences';
import useAskMutation from '../hooks/useAskMutation';
import { useIsMobile } from '../hooks/useIsMobile';
import { LeftMenuMobile } from './LeftMenuMobile';
import { LeftMenuDesktop } from './LeftMenuDesktop';
import { ConversationType, PromptModes, DocumentModeType } from '../components/styled/various'; 
import { useTranslation } from 'react-i18next';
import { createNewConversation } from '../common/createNewConversation';
import { RegularPrompt } from './RegularPrompt';
import { DocumentPrompt } from './DocumentPrompt';
import { backendUrl } from '../i18n';
import { set } from 'lodash';


export const Page: React.FC = () => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const userId = localStorage.getItem('userId');
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }  

  const [promptMode, setPromptMode] = React.useState<PromptModes>('regularMode');
  const [textAreaValue, setTextAreaValue] = React.useState<string>('');
  const [captchaToken, setCaptchaToken] = React.useState<string>('');
  const [pyetShqipJwtToken, setPyetShqipJwtToken] = React.useState<string>('');
  const [conversationId, setConversationId] = React.useState<string | null>(localStorage.getItem('conversationId'));
  const [currentCategory, setCurrentCategory] = React.useState<string | 'general'>(localStorage.getItem('currentCategory') || 'general');
  const [documentMode, setDocumentMode] = React.useState<DocumentModeType>('showDocument');

  const [errorMessage, setErrorMessage] = React.useState<string>('');


  const { data: currentConversation, isLoading: conversationIsLoading, refetch } = useGetConversationSentences(userId || '', conversationId || '', () => null);
  const { data: conversationIdsAndNames, error: errorConversationIds, refetch: refetchConversationNames } = useGetConversationIds(userId || '');
  const { isLoading: isLoadingMutation, mutateAsync } = useAskMutation();


  const handleCategoryChange = (newCategory: string) => {
    localStorage.setItem('currentCategory', newCategory);
    setCurrentCategory(newCategory);
  }

  const handleChange = (event: any) => {
    setTextAreaValue(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    currentConversation && currentConversation.sentences.push(textAreaValue); // Show the newest question, until we get the generated answer from API

    await mutateAsync({      
      question: textAreaValue,
      category: currentCategory,
      pyetShqipJwtToken,
      userId: userId || '',
      conversationId: conversationId || ''
    }, {
      onSuccess: (newData: { data: ConversationType}) => {
        // Update the cache with the new data
        refetch();
        setTextAreaValue('');
        if (newData.data.sentences.length === 2) {
          // If it's a new conversation, update conversations on the left side
          refetchConversationNames();
        }
      },
      onError: (error: any) => {
        setErrorMessage(error.response.data.message);       
      }
    }
    );

    scrollToBottom();

  };




  const changeConversationId = async (newConversationId: string) => {
    setConversationId(newConversationId);
  }

  const verifyRecaptchaCallback = React.useCallback((token: string) => {
    setCaptchaToken(token)
  }, []);



  React.useEffect(() => {


    const getFirstUserId = async () => {
      const currentUserId = localStorage.getItem('userId');

      if (currentUserId) {
        // Do something with userId
        console.log(`userId is: ${currentUserId}`);
      } else {
        // There is no userId set in localStorage, get one and set it
        const response = await axios.get<{ userId: string }>(
          `${backendUrl}/getUserId`
        );
        localStorage.setItem('userId', response.data.userId);
      }
    };

    const getPyetShqipJwtToken = async () => {
      if (pyetShqipJwtToken) {
        // Do something with pyetShqipJwtToken
        console.log(`pyetShqipJwtToken is: ${pyetShqipJwtToken}`);
      } else {
        const payload = {
          userId
        }

        const response = await axios.post<{ pyetShqipToken: string }>(
          `${backendUrl}/getPyetShqipToken/${captchaToken}`,
          payload
        );
        // console.log('Getting pyetShqipToken again');
        setPyetShqipJwtToken(response.data.pyetShqipToken);
      }
    };

    const getFirstConversationId = async () => {
      const currentConversationId = localStorage.getItem('conversationId');
      const currentConversationIdExpiryTime = localStorage.getItem('conversationIdExpiryTime') || new Date();
      const expiryDateObject = new Date(Number(currentConversationIdExpiryTime));
      const now = new Date();
      const expirySecondsDifference = ( now.getTime() - expiryDateObject.getTime()) / 1000 ;

      // console.log(`expirySecondsDifference is: ${expirySecondsDifference} seconds`)

      if (!currentConversationId || (expirySecondsDifference > 21600)) { // 21,600 seconds = 6 hours
        const response = await axios.get<{ conversationId: string, conversationIdExpiryTime: string }>(
          `${backendUrl}/getNewConversationToken`
        );
        setConversationId(response.data.conversationId);
        localStorage.setItem('conversationId', response.data.conversationId);
        localStorage.setItem('conversationIdExpiryTime', response.data.conversationIdExpiryTime);          
      }
    };  

    getFirstUserId();
    getPyetShqipJwtToken();
    getFirstConversationId();
    // getConversationNames(conversationIdsAndTopicNames, userId || '', setConversationIdsAndTopicNames);
    scrollToBottom();
  }, [captchaToken, conversationId, pyetShqipJwtToken, userId, refetch]);

  return (
    <FormContainer>
      
      <GoogleReCaptcha 
        onVerify={verifyRecaptchaCallback}
      />
        <Logo src={`/logos/${t('logoFile')}`} alt="Logo" style={{ marginBottom: '15px'}} width={isMobile ? '100%' : '70%'} />
        {/* <p>{t('underConstruction')}</p> */}
        <>
        {errorConversationIds && 
          <p>{t('problemsObtainingConversations')}  </p>
        }
        </>

        <Flex  
          flexDirection={isMobile ? 'column' : 'row'}
          >
          {isMobile ? (
            <LeftMenuMobile 
              options={conversationIdsAndNames || []}  
              currentConversationId={conversationId || ''}
              changeConversationId={changeConversationId}
              createNewConversation={createNewConversation}
              setConversationId={setConversationId}
              currentCategory={currentCategory}
              handleCategoryChange={handleCategoryChange}
              setPromptMode={setPromptMode}
            />
          ) : (
            <LeftMenuDesktop 
              conversationIdsAndNames={conversationIdsAndNames}
              currentConversationId={conversationId}
              changeConversationId={changeConversationId}
              createNewConversation={createNewConversation}
              setConversationId={setConversationId}
              currentCategory={currentCategory}
              handleCategoryChange={handleCategoryChange}
              setPromptMode={setPromptMode}             
            />
          )}          


          { promptMode === 'regularMode' ? (
            <RegularPrompt 
              isMobile={isMobile}
              isLoadingMutation={isLoadingMutation}
              currentConversation={currentConversation}
              conversationIsLoading={conversationIsLoading}
              errorMessage={errorMessage}
              textAreaValue={textAreaValue}
              captchaToken={captchaToken}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          ) : promptMode === 'pdfMode' && userId ? ( 
            <DocumentPrompt 
              userId={userId} 
              isMobile={isMobile}
              isLoadingMutation={isLoadingMutation}
              currentConversation={currentConversation}
              conversationIsLoading={conversationIsLoading}
              errorMessage={errorMessage}
              textAreaValue={textAreaValue}
              captchaToken={captchaToken}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              documentMode={documentMode}
              setDocumentMode={setDocumentMode}
              setConversationId={setConversationId}
              currentConversationId={conversationId}           
            />
          ) : (
            <p>Something went wrong...</p>
          )}  
      </Flex>

      <Flex justifyContent='center' alignItems='center'>
        <p style={{ fontSize: '12px', marginBottom: '30px', marginTop: '30px' }}>{t('footerText')}</p>
      </Flex>     
      
      <div ref={messagesEndRef} />
    </FormContainer>
  )
}