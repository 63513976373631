import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { UploadButton } from './styled/various';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from './ProgressBar';
import { backendUrl } from '../i18n';



interface UploadFileProps {
  userId: string | null;
  isFileUploaded: boolean;
  setCurrentConversationId: (newConversationId: string) => void,
  setIsFileUploaded: (isFileUploaded: boolean) => void;
  refetchCurrentConversation: () => void;
  refetchDocumentConversations: () => void;
}

export const UploadFile: React.FC<UploadFileProps> = ({ 
  userId,
  isFileUploaded,
  setCurrentConversationId,
  setIsFileUploaded,
  refetchCurrentConversation,
  refetchDocumentConversations
 }: UploadFileProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [progress, setProgress] = React.useState<number>(0);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    }
    
  });

  const uploadFile = useMutation(async ({ file, userId } : { file: File, userId: string }) => {    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId);

    const response = await axios.post(`${backendUrl}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent: any) => {
          let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if(percentCompleted === 100) percentCompleted = 99;
          setProgress(percentCompleted);
        }
    });
    return response.data;
  });

  const handleUpload = async (event: any) => {
    try {
      if (file && userId) {
        const { conversationData } = await uploadFile.mutateAsync({ file, userId });
        setCurrentConversationId(conversationData.conversationId);

        console.log('File uploaded successfully');
        setFilename(file.name);
        setFile(null);
        setIsFileUploaded(true);
        refetchDocumentConversations();
        refetchCurrentConversation();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const files = event.target.file ? event.target.file.files : [];

    if (files.length > 0) {
      handleUpload(files);
    }
  };

  return (
    <div className="upload-container">

      {isFileUploaded ? (
        <>
          <p>{t('general.file')}: <br /><strong>{filename}</strong></p>        
          <p>{t('pdf.yourFileHasBeenUploaded')}</p>
        </>
        ) : (
          <>
            <div {...getRootProps()} className="dropzone">
              <form onSubmit={handleSubmit}>
                <input {...getInputProps()} />
                {file ? (
                  <div>

                    <p>{t('general.chosenFile')}:<br /><strong>{file.name}</strong></p>
                    
                    {progress > 0 && 
                      <ProgressBar progress={progress} width={300} height={20} color="green" />
                    }                      
                  
                  </div>
                ) : (
                  <>
                    <p>{t('pdf.clickChoosePdf')}</p>
                    <UploadButton onClick={open}>
                      {t('pdf.buttons.selectFile')}
                    </UploadButton>
                  </>
                )}
              </form>       
            </div>

            {file && 
              <UploadButton onClick={handleUpload} disabled={progress > 0}>
                {progress === 0 ? t('pdf.buttons.uploadFile') : t('pdf.buttons.uploadingFile')}
              </UploadButton>
            }
            <span style={{ fontSize: '12px' }}>{t('documentDisclaimer')}</span>
        </>
        )
      } 

    </div>
  );
};

