import { useState, useEffect } from 'react';

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768); // You can adjust the breakpoint here
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen to resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up
    };
  }, []);

  return isMobile;
}