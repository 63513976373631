import axios from 'axios';
import { useQuery } from 'react-query';
import { ConversationType } from '../components/styled/various'; 
import { backendUrl } from '../i18n';


const fetchConversation = async (userId: string, conversationId: string): Promise<ConversationType> => {
  const response = await axios.post(`${backendUrl}/getConversationById`, { 
    userId,
    conversationId
   });
  return response.data;
}

const useGetConversationSentences = (userId: string, conversationId: string, onSuccess: any) => {
  return useQuery(['conversation', conversationId], () => {
    return fetchConversation(userId, conversationId);
  }, {
    onSuccess
  });
}

export default useGetConversationSentences;