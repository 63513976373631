import React from 'react';
import styled from 'styled-components';

export const colors = {
  primary: '#1742b2',
  secondary: '#c54e82',
  success: '#15ac65',
  danger: '#f41e08',
  warning: '#e4cd14',
  light: '#2a3198',
  dark: '#0e184c'
};

export const ErrorMessage = styled.p`
  color: red;
  font-size: 1rem;
  margin: 0.5rem 0;
`;

export const FormContainer = styled.div`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.img`
  width: ${(props) => props.width || "100%"};
`;

export const TextArea = styled.textarea`
  width: 95%;
  height: 100px;
  padding: 1rem;
  font-size: 1rem;
  border: none;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`;

export const UploadButton = styled.button`
  width: 95%;
  padding: 5px 5px;
  margin: 0 auto;
  font-size: 1rem;
  border: none;
  background-color: ${colors.dark};
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px; 
  ${(props) => props.disabled && `
    background-color: #ddd;
    color: #999;
    cursor: not-allowed;
  `}  
  `; 

export const LeftMenuButton = styled.button`
  margin: 0 auto;
  padding: 1rem 1rem;
  font-size: 1rem;
  border: none;
  background-color: #2a9d8f;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out; 

  ${(props) => props.disabled && `
    background-color: #ddd;
    color: #999;
    cursor: not-allowed;
  `}

  ${(props) => !props.disabled && `

    background-color: white;
    color: black;
    &:hover {
      background-color: lightblue;
      cursor: pointer;
    }    
  `} 
  
  `;

export const SubmitButton = styled.button`
  display: block;
  margin: 0 auto;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  background-color: #2a9d8f;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;


  ${(props) => props.disabled && `
    background-color: #ddd;
    color: #999;
    cursor: not-allowed;
  `}

  ${(props) => !props.disabled && `
    background-color: #2a9d8f;
    color: white;
    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      background-color: #218c74;
    }    
  `}
  
  `;

  interface SentenceBoxrops {
    color?: string;
    backgroundColor?: string;
    customWidth?: string
  }
  
  export const SentenceBox = styled.div<SentenceBoxrops>`
    width: ${(props) => props.customWidth || "100%"};
    font-size: 18px;
    text-align: left;
    color: ${(props) => props.color || "#333"};
    background-color: ${(props) => props.backgroundColor || "#fff"};
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    border-radius: 10px;
    word-break: break-word;
  `;
  
  export const Author = styled.div`
    font-size: 14px;
    font-style: italic;
    text-align: right;
    color: ${(props) => props.color || "#666"};
    margin-top: 10px;
  `;
  
  export type SentenceComponentProps = {
    sentenceText: string,
    author: string,
    color: string,
    backgroundColor: string,
    customWidth?: string
  }
  
  export const SentenceComponent = ({
    sentenceText,
    author, 
    color,
    backgroundColor, 
    customWidth
  }: SentenceComponentProps) => {
    const lines = sentenceText.split('\n');
    return (
      <SentenceBox 
        color={color}
        backgroundColor={backgroundColor}
        customWidth={customWidth} 
      >
        
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index !== lines.length - 1 && <br />}
          </React.Fragment>
        ))}
        
        <Author
          color={color}
        >
            {author}
        </Author>

      </SentenceBox>
    )
  }

  export type conversationIdAndTopicNameType = {
    conversationId: string,
    topicName: string
  }

  export type ConversationType = {
    conversationId: string,
    userId: string,
    sentences: string[],
    filename?: string,
  }

  export type PromptModes = 'regularMode' | 'pdfMode' ;
  export type DocumentModeType = 'addDocument' | 'showDocument';

  export type MainPromptProps = {
    userId?: string,
    conversationId?: string,
    isMobile: boolean,
    isLoadingMutation: boolean,
    currentConversation?: ConversationType | undefined,
    errorMessage: string, 
    textAreaValue: string,
    captchaToken: string,
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    handleSubmit: (event: React.MouseEvent) => void,
    setDocumentMode?: (documentMode: DocumentModeType) => void,
    conversationIsLoading: boolean,
    documentMode?: DocumentModeType
  }
