import React from 'react';
import { Page } from './components/Page';
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query'
import './i18n';


function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App" id="outer-container">
      <div id="page-wrap">

        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider reCaptchaKey="6LfodNQkAAAAAPI4geTx4WoVli3d_V5abAD2VnOz">
              <Page />  
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
 
      </div>
    </div>
  );
}

export default App;
