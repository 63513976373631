import axios from 'axios';
import { useQuery } from 'react-query';
import { backendUrl } from '../i18n';

type conversationIdAndTopicNameType = {
  conversationId: string,
  topicName: string
}

const fetchConversationIdAndTopicNames = async (userId: string): Promise<conversationIdAndTopicNameType[]> => {
  const response = await axios.post(`${backendUrl}/getConversationNames`, { userId });
  return response.data;
}

const useGetConversationIds = (userId: string) => {
  return useQuery(['conversationIdAndTopicName', userId], () => fetchConversationIdAndTopicNames(userId));
}

export default useGetConversationIds;
