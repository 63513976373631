import React from 'react';
import {
  Flex,
} from 'rebass';
import { conversationIdAndTopicNameType, PromptModes } from './styled/various';
import { useTranslation } from 'react-i18next';
import { Categories } from '../components/Categories';
import { DropdownCustom } from './DropDownCustom';
import { MainButton } from './MainButton';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { colors } from './styled/various';

type LeftMenuMobileType = { 
  options: conversationIdAndTopicNameType[],
  currentConversationId: string,
  changeConversationId: (newConversationId: string) => void,
  createNewConversation: any,
  setConversationId: any,
  handleCategoryChange: (newCategory: string) => void,
  currentCategory: string,
  setPromptMode: (newPrompt: PromptModes) => void,  
}

export function LeftMenuMobile({ 
  options,
  currentConversationId, 
  changeConversationId, 
  createNewConversation,
  setConversationId,
  handleCategoryChange,
  currentCategory,
  setPromptMode
} : LeftMenuMobileType ) {

  const { t } = useTranslation();

  const optionsForDropdown = options.map((row: conversationIdAndTopicNameType) => {
    return {  
      label: row.conversationId,
      description: row.topicName
    }
  })

  return (
    <Flex flexDirection={'column'}>

          <Flex>
            <DropdownCustom 
              title={t('general.conversations')}
              currentOption={currentCategory}
              options={optionsForDropdown}
              handleOptionChange={changeConversationId}
              setPromptMode={setPromptMode}
              isMobile          
            />

            <Categories
              key='categoriesMobile' 
              handleCategoryChange={handleCategoryChange} 
              currentCategory={currentCategory}
              isMobile
            />
          </Flex>

          <MainButton 
            key='analyzeYourDocuments'
            title={t('analyzeYourDocuments')}
            backgroundColor={colors.dark}
            onClick={() => setPromptMode('pdfMode')}
            faSymbol={faUpload}
          />
   
          <MainButton 
            key='createNewTopic'
            title={t('createNewTopic')}
            backgroundColor={colors.dark}
            onClick={() => {
              createNewConversation(setConversationId);
              setPromptMode('regularMode');
            }}
            faSymbol={faPlus}          
          /> 

    </Flex>  
  );
}
