import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import sqTranslation from './locales/sq/translation.json';
import czTranslation from './locales/cz/translation.json';
import seTranslation from './locales/se/translation.json';


function determineLanguage(hostname: string) {
  let selectedLanguage, backendUrl;

  if(hostname.includes('localhost')) {
    selectedLanguage = 'sq';
    backendUrl = 'http://localhost:7001';
  
  } else if(hostname.includes('pyetshqip.com')){
    selectedLanguage = 'sq';
    backendUrl = 'https://api.pyetshqip.com'
  
  } else if(hostname.includes('polozteotazku.online')){
    selectedLanguage = 'cz';
    backendUrl = 'https://api.polozteotazku.online'
  
  } else if(hostname.includes('fragapasvenska.online')){
    selectedLanguage = 'se';
    backendUrl = 'https://api.fragapasvenska.online'
  
  } else {
    selectedLanguage = 'en';
    backendUrl = 'https://api.pyetshqip.com';
  }

  return { selectedLanguage, backendUrl};
}


const resources = {
  en: {
    translation: enTranslation
  },
  sq: {
    translation: sqTranslation
  },
  cz: {
    translation: czTranslation
  },
  se: {
    translation: seTranslation
  },     
}

const hostname = window.location.hostname;
export const { selectedLanguage, backendUrl } = determineLanguage(hostname);


i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    lng: selectedLanguage
  });

  export default i18n;
