import axios from 'axios';
import { useQuery } from 'react-query';
import { backendUrl } from '../i18n';

const fetchConversations = async (userId: string) => {
  const response = await axios.post(`${backendUrl}/getDocumentConversations`, { userId });
  return response.data;
}

const useGetDocumentConversations = (userId: string, onSuccess: any) => {
  return useQuery(['documentConversations', userId], 
  () => fetchConversations(userId)
  , {
    onSuccess,
    enabled: true
  });
}

export default useGetDocumentConversations;