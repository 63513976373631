import {
  Flex,
  Button
} from 'rebass';
import { colors, PromptModes } from './styled/various';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isEmpty } from 'lodash';

type MainButtonProps = {
  key: string,
  title: string,
  conversationId?: string,
  currentConversationId?: string,
  changeConversationId?: (newConversationId: string) => void,  
  setPromptMode?: (newConversationId: PromptModes) => void,
  color?: string,
  backgroundColor?: string,
  fontSize?: string,
  onClick?: () => void,
  faSymbol?: IconProp,
} 

export const MainButton = ({
  key,
  title,
  conversationId,
  currentConversationId,
  changeConversationId,
  setPromptMode,
  color = 'white',
  backgroundColor = colors.light,
  fontSize = '16px',
  onClick,
  faSymbol
}: MainButtonProps) => {
  return (
        <Button
          key={key} 
          variant='primary' 
          mr={2}
          color={color}
          backgroundColor={backgroundColor}
          mb={'5px'}
          sx={{
            cursor: 'pointer',
            textDecoration: conversationId && conversationId === currentConversationId ? 'underline' : 'none',
            maxWidth: '200px',
            textAlign: 'left',
            fontSize,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={onClick}
        >
            <Flex justifyContent='space-between' alignItems='center'>

              {title}

              {faSymbol && !isEmpty(faSymbol) && 
                <FontAwesomeIcon icon={faSymbol} style={{marginLeft: '5px'}}/>
              }

            </Flex>

        </Button>
  )
}; 