import React from 'react';
import {
  Flex,
} from 'rebass';
import { useTranslation } from 'react-i18next';
import { DropdownCustom } from './DropDownCustom';

type Category = {
  label: string,
  description: string
}

type CategoriesComponentType = {
  handleCategoryChange: (newCategory: string) => void,
  currentCategory: string,
  isMobile?: boolean,
  title?: string
}


export function Categories({ 
  handleCategoryChange,
  currentCategory, 
  isMobile,
  title
} : CategoriesComponentType) {
  const { t } = useTranslation();
  const categories: Category[] = t('categories', { returnObjects: true });

  return (
    <Flex sx={{ width: '100%' }}>

      <DropdownCustom 
        title={isMobile ? t('general.category') : t('changeCategory')}
        currentOption={currentCategory}
        options={categories}
        handleOptionChange={handleCategoryChange}
      />

    </Flex>  
  );
}
